import { useMutation } from '@tanstack/react-query'
import { useRouter, useSearchParams } from 'next/navigation'
import React from 'react'

import { useSession } from '@/contexts/auth/SessionProvider'
import { LOGIN_REDIRECT_ROUTE } from '@/utils/constants'
import { defaultToastError } from '@/utils/etc'
import * as fetch from '@/utils/fetch'

export function useAuth() {
  const { push } = useRouter()
  const searchParams = useSearchParams()
  const session = useSession()

  const login = useMutation({
    mutationFn: async ({
      credencial,
      senha,
    }: {
      credencial: string
      senha: string
    }) => {
      const getRolesResult = await fetch
        .portal<{ roles: string[] }>('/auth/credencial/buscar-roles', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ credencial, senha }),
        })
        .then(({ data }) => data)

      if (!getRolesResult?.roles?.[0]) return null

      const role = getRolesResult.roles[0]

      const signInResult = await fetch
        .portal<{ token: string }>(
          `/auth/login/${role !== 'VENDEDOR' ? role : 'INTEGRADOR'}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              login: { credencial, senha },
            }),
          }
        )
        .then(({ data }) => data?.token)

      if (!signInResult) return null

      const currentSession = await session.update()

      let queryCallbackUrl = searchParams?.get('callbackUrl')
      queryCallbackUrl =
        queryCallbackUrl && decodeURIComponent(queryCallbackUrl)

      // If trying to login to an invalid area, redirect to the defalt LOGIN_REDIRECT_ROUTE
      const area = queryCallbackUrl?.match(/^\/area-(.+?)\//)?.[1]
      if (
        area !== currentSession?.credential?.roles?.[0]?.toLowerCase() &&
        queryCallbackUrl?.startsWith('/area-')
      ) {
        queryCallbackUrl = undefined
      }

      const callbackUrl = queryCallbackUrl || LOGIN_REDIRECT_ROUTE

      // Full reload feels more responsive
      location.assign(callbackUrl)
    },
    onError: defaultToastError,
  })

  const logout = React.useCallback(
    async (callbackUrl?: string) => {
      await fetch
        .portal('/auth/logout', { method: 'POST' })
        .catch(defaultToastError)
      localStorage.removeItem('clickAnouncements')
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key?.toLowerCase().startsWith('tablestate')) {
          localStorage.removeItem(key)
        }
      }
      push(
        `/login?callbackUrl=${encodeURIComponent(
          callbackUrl || location.pathname
        )}`
      )
      window.location.reload()
    },
    [push]
  )

  return {
    user: {
      perfil: session?.data?.profile,
      credencial: session?.data?.credential,
    },
    context: session?.data?.role,
    login: login.mutateAsync,
    logout,
    update: session.update,
  }
}
